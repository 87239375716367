import { Factory } from "fishery";
import { Permission, Role } from "./entities";
import { faker } from "@faker-js/faker";
import { RolesPaginatedResponse } from "./monolith";
import { PermissionsResponse } from "@accounts/api/monolith";

export const PermissionFactory = Factory.define<Permission>(() => ({
  id: faker.number.int(),
  feature: faker.helpers.arrayElement([
    "account_management",
    "vendor_master",
    "messaging",
    "payments",
    "portal_setup",
    "reporting",
  ]),
  sub_feature: faker.lorem.slug(),
  codename: faker.lorem.slug(),
  name: faker.hacker.phrase(),
  description: faker.lorem.paragraph(),
  staff_only: faker.datatype.boolean({ probability: 0.1 }),
}));

export const RoleFactory = Factory.define<Role>(({ params }) => ({
  id: params?.id ? params?.id : faker.number.int(),
  name: faker.lorem.word({ length: { min: 1, max: 100 } }),
  description: faker.lorem.word({ length: { min: 0, max: 255 } }),
  staff_only: false,
  is_base_role: false,
  app_permissions: [],
  is_editable: false,
  count_users_assigned: 0,
  created_at: faker.date.anytime().toISOString(),
  modified: faker.date.anytime().toISOString(),
}));

export const RolesResultFactory = Factory.define<RolesPaginatedResponse>(
  ({ params }) => {
    const roles = params?.results ?? RoleFactory.buildList(10);

    return {
      count: params?.count ?? roles.length,
      previous: params?.previous ?? null,
      next: params?.next ?? null,
      results: roles,
    };
  },
);

export const PermissionsResultFactory = Factory.define<PermissionsResponse>(
  ({ params }) => {
    const permissions = params?.results ?? PermissionFactory.buildList(10);

    return {
      results: permissions,
    };
  },
);
